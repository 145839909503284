import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit"

import { Player, Game, AuthResponse } from './type'

import { Endpoints } from "../../../const/Api"
import { client } from "../../../tool/client"
import { RootState } from "../../../store"
import { LangKeys } from "../../../const/LangKeys"
import { setLastBet } from "../../betbar/reducer"

interface AuthState {
    player: Player | null 
    game: Game | null

    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | null | undefined,
}

let domain: any;
export const authLogin = createAsyncThunk(
    'auth/login', 
    async (_, { dispatch }) => {
      domain = domain ||  await (await fetch(Endpoints.Domain)).json();

        const response = await client.get(domain.auth, {})

        const data = response.data.data as AuthResponse
        const { player, game } = data

        let active_chip = 0
        if (game.chip_base.length > 0) {
            active_chip = game.chip_base[0]
        }

        const { player_language, last_bet } = player
        
        // cek valid language
        let valid_language = player_language.toLowerCase()
        if (LangKeys.indexOf(valid_language) === -1) {
            valid_language = 'id'
        }

        // add last bet to player bet
        if (last_bet.length > 0) {
            dispatch(setLastBet(last_bet))
        }

        const payload = {
            game: {
                ...game,

                volume: {
                    effect: 100,
                    music: 100
                },
                active_chip,
                popup_message: '',
                active_menu: null
            },
            player: {
                ...player,

                player_language: valid_language
            }
        }

        return payload
    }
)

const initialState: AuthState = {
    player: null,
    game: null,

    status: 'idle',
    error: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateBalance: (state, action: PayloadAction<number>) => {
            if (state.player) {
                state.player.player_balance += action.payload
            }
        },
        setPopupMessage: (state, action: PayloadAction<string>) => {
            if (state.game) {
                state.game.popup_message = action.payload
            }
        },
        setActiveChip: (state, action: PayloadAction<number>) => {
            if (state.game) {
                state.game.active_chip = action.payload
            }
        },
        setActiveMenu: (state, action: PayloadAction<string | null>) => {
            if (state.game) {
                state.game.active_menu = action.payload
            }
        },
        setMusic: (state, action: PayloadAction<boolean>) => {
            if (state.game) {
                client.post(Endpoints.Settings, {
                    effect: state.game.sounds.effect,
                    music: action.payload,
                    language: (state.player?.player_language || '').toUpperCase()
                })

                state.game.sounds.music = action.payload
            }
        },
        setEffect: (state, action: PayloadAction<boolean>) => {
            if (state.game) {
                client.post(Endpoints.Settings, {
                    effect: action.payload,
                    music: state.game.sounds.music,
                    language: (state.player?.player_language || '').toUpperCase()
                })

                state.game.sounds.effect = action.payload
            }
        },
        setVolumeMusic: (state, action: PayloadAction<number>) => {
            if (state.game) {
                state.game.volume.music = action.payload
            }
        },
        setVolumeEffect: (state, action: PayloadAction<number>) => {
            if (state.game) {
                state.game.volume.effect = action.payload
            }
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            if (state.player && state.game) {
                const sendLang = action.payload.toUpperCase()

                client.post(Endpoints.Settings, {
                    ...state.game.sounds,
                    
                    language: sendLang
                })

                state.player.player_language = action.payload
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(authLogin.pending, (state, action: PayloadAction) => {
                state.status = 'loading'
            })
            .addCase(authLogin.fulfilled, (state, action: PayloadAction<{ player: Player, game: Game }>) => {
                state.status = 'succeeded'
                state.game = action.payload.game
                state.player = action.payload.player
            })
            .addCase(authLogin.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const {
    updateBalance,
    setPopupMessage,
    setActiveChip,
    setActiveMenu,
    setMusic,
    setEffect,
    setVolumeMusic,
    setVolumeEffect,
    setLanguage
} = authSlice.actions

export default authSlice.reducer

export const getPlayer = (state: RootState) => state.auth.player
export const getGame = (state: RootState) => state.auth.game