import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import BGViewAll from './view-all.png'
import "./index.scss";
import Table from "./table";
import { useAnimateOpening } from "../betbar/dropdown";
import Out from "./out";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { History as HistoryType } from "./type";
import { getColor } from "../color";
import { closeViewAll } from "./reducer";

const History = () => {
  const { t, i18n } = useTranslation();

  const animate = useAnimateOpening('flex')
  const animateOut = useAnimateOpening('flex')
  const { handleOpen } = animate;
  const {last_history: historyData, viewAll} = useAppSelector((state) => state.history)

  const [dataX, setDataX] = useState<any>({})
  const [outIdx, setOutIdx] = useState<any>(-1)
  const [ color, setColor ] = useState();

  useEffect(()=>{
   (async()=>{
    setColor(await getColor())
  })()
  }, [])
  
  useEffect(()=>{
    const history: any = {};
    setDataX(history)
    historyData.map((data: HistoryType)=>{
      if(!history[data.prize.value]){
        history[data.prize.value] = [];
      }
      history[data.prize.value].unshift(data);
    })
    setDataX(history);
  }, [historyData, historyData.length])


  return (
    <div className="history">
      {Object.keys(dataX).length?<><div className="history-list">
        {
          Object.keys(dataX).map((key, idx) => {
          return (
            <div
              key={idx+"".concat("list-item-history")}
              className="list-item" 
              onClick={()=>{
                historyData.map(({now}, idx)=>{
                  if(now==dataX[key][0].now){
                    setOutIdx(idx)
                  }
                })
                animateOut.handleOpen()}}
              style={{backgroundColor: `rgba(${color && color[dataX[key][0].data.detail_bet.rows][dataX[key][0].prize.position-1]},1)`}}
            >
              <div className="item-text">
                {dataX[key][0].prize.value}x {dataX[key].length}
              </div>
            </div>)
})
        }
        {/* {
        historyData.map((item, key) => {
          return (
            <div
              key={key+performance.now()}
              className="list-item" 
              style={{backgroundColor: `rgba(${color && color[item.data.detail_bet.rows][item.prize.position-1]},1)`}}
            >
              <div className="item-text">
                {item.prize.value}
              </div>
            </div>)
}).reverse()
        } */}
      </div>

        <div className="history-view_all" onClick={()=>{
            handleOpen()
          }}>
        <img className='history-view_all-bg' alt='' src={BGViewAll} />
          <span className="view_all-text">{t(`history.view_all`)}</span>
        </div></>:<></>}
        <Table {...{...animate}}/>
      {outIdx>-1?<Out {...{...animateOut, dataHistory: historyData[outIdx], setOutIdx}}/>:<></>}

    </div>
  );
};

export default History;
