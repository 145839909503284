import React, { useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import HistoryIcon from "./icon_history.png";
import VolumeIcon from "./icon_volume.png";
import TutorialIcon from "./icon_tutorial.png";
import FullscreenlIcon from "./icon_fullscreen.png";
import NotFullscreenlIcon from "./icon_notfullscreen.png";
import BGSetting from "./icon-bar.png";
import InfoIcon from "./icon-button.png";
import BGRange from "./Background.png";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { setEffect, setMusic, setVolumeEffect, setVolumeMusic } from "../user/auth/reducer";
import sound from "../../sound";
import HeaderInfo from "../info";
import { closeViewAll, openViewAll } from "../history/reducer";
import { FormControlLabel, Grid, Switch } from "@mui/material";

const Setting = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [showVolBar, setShowVolBar] = useState(false);
  const [showInfoBar, setShowInfoBar] = useState(true);
  const music = useAppSelector(
    (state) => state.auth.game?.sounds.music
  ) as boolean;
  const effect = useAppSelector(
    (state) => state.auth.game?.sounds.effect
  ) as boolean;
  const volumeMusic = useAppSelector(
    (state) => state.auth.game?.volume.music
  ) as number;
  const volumeEffect = useAppSelector(
    (state) => state.auth.game?.volume.effect
  ) as number;
  const volumeMusicRef = useRef<HTMLInputElement>(null);
  const volumeSfxRef = useRef<HTMLInputElement>(null);
  const handleChangeMusic = () => {
    dispatch(setMusic(!music));
  };
  useEffect(() => {
    if (volumeMusicRef.current) {
      volumeMusicRef.current.style.backgroundSize =
        ((volumeMusic - MIN) * 100) / (MAX - MIN) + "% 100%";
    }

    sound.updateVolumeMusic(volumeMusic);
  }, [volumeMusic]);

  useEffect(() => {
    sound.enableSfx = (effect);
  }, [effect]);


  useEffect(() => {
    if (volumeSfxRef.current) {
      volumeSfxRef.current.style.backgroundSize =
        ((volumeEffect - MIN) * 100) / (MAX - MIN) + "% 100%";
    }

    sound.updateVolumeSfx(volumeEffect);
  }, [volumeEffect]);

  const [isFullScreen, setFullscreen] = useState(false);
  const setting = useMemo(
    () => [
      {
        icon: (
          <img width={'46px'} title={t("setting.history")!} alt="history" src={HistoryIcon} />
        ),
        action: () => {
          dispatch(openViewAll())
          setTimeout(() => {
            dispatch(closeViewAll())
          }, 500);
        },
      },
      {
        icon: (
          <img title={t("setting.volume")!} alt="volume" src={VolumeIcon} />
        ),
        action: () => setShowVolBar((old) => !old),
      },
      {
        icon: (
          <img
            title={t("setting.tutorial")!}
            alt="tutorial"
            src={TutorialIcon}
          />
        ),
        action: () =>
          window.open("https://idnelectroniclobby.com/tutorial/plinko"),
      },
      {
        // @ts-ignore
        icon: !isFullScreen ? (
          <img
            title={t("setting.fullscreen")!}
            alt="fullscreen"
            src={FullscreenlIcon}
          />
        ) : (
          <img
            title={t("setting.exit_fullscreen")!}
            alt="exit_fullscreen"
            src={NotFullscreenlIcon}
          />
        ),
        action: () => {
            // @ts-ignore
          if(typeof document.documentElement.requestFullscreen!== "function") return;
          if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setFullscreen(true);
          } else if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullscreen(false);
          }
        },
      },
      // {
      //   icon: <img title={t("setting.info")!} alt="info" src={InfoIcon} />,
      //   action: () => setShowInfoBar((old) => !old),
      // },
    ],
    [isFullScreen]
  );
  return (
    <div className="setting">
      <img className="setting-bg" alt="" src={BGSetting} />
      <div className="setting-list">
        {setting.map(
          (
            item: {
              icon: any;
              action: () => void;
            },
            key
          ) => (
            <div key={key} className="list-item" onClick={() => item.action()}>
              {item.icon}
            </div>
          )
        )}
      </div>
      <div className="setting-volume">
        <div className="setting-sound-content">
          <div className="setting-sound-item">
            {showVolBar ? (
              <div style={{
                background: 'white', borderRadius
                  : '1rem', padding: '0.5rem 1rem'
              }}>
                <img className="setting-bg-range" alt="" src={BGRange} />

                <Grid item >

                  <FormControlLabel

                    control={<Switch onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(setMusic(true))
                      } else {
                        dispatch(setMusic(false))

                      }
                    }} checked={music} color="info" />}
                    label="Musik"
                    labelPlacement="start"
                  />
                  <input
                    type="range"
                    min={MIN}
                    max={MAX}
                    value={volumeMusic}
                    className="volume-range"
                    ref={volumeMusicRef}
                    onChange={(e) =>
                      dispatch(setVolumeMusic(Number(e.target.value)))
                    }
                  />
                </Grid>
                <Grid item>

                  <FormControlLabel
                    // value="start"
                    control={<Switch onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(setEffect(true))
                      } else {
                        dispatch(setEffect(false))

                      }
                    }} checked={effect} color="primary" />}
                    label="Efek"
                    labelPlacement="start"
                  />
                  <input
                    type="range"
                    min={MIN}
                    max={MAX}
                    value={volumeEffect}
                    className="volume-range"
                    ref={volumeSfxRef}
                    onChange={(e) =>
                      dispatch(setVolumeEffect(Number(e.target.value)))
                    }
                  />
                </Grid>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {true ? <HeaderInfo handleOpen={() => setShowInfoBar((old) => !old)} /> : <></>}
    </div>
  );
};

const MIN = 0;
const MAX = 100;

export default Setting;
