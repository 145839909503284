import React from 'react'
// @ts-ignore
import ReactDOM from 'react-dom/client'

import {Provider} from 'react-redux'

import './lang/i18n'
import store from './store'
import './index.scss'
import App from './ui/app'
import {fetchHistory} from './ui/history/reducer'
import {authLogin} from './ui/user/auth/reducer'

async function main() {
    // Bugsnag.start({
    // 	apiKey: 'bf7b9fc6e57180ecd52ba3f56311a7ae',
    // 	plugins: [new BugsnagPluginReact()],
    // 	// otherOptions: value
    // });
    // const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

    store.dispatch(authLogin())
    store.dispatch(fetchHistory())

    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        <React.StrictMode>
            <Provider store={store}>
                <App/>
            </Provider>
        </React.StrictMode>
    )
}

main()
