import bgm from './bgm.mp3'
import ball1 from './ball 1.wav'
import ball2 from './ball 2.wav'
import ball3 from './ball 3.wav'
import hole_effect from './hole effect.wav'





const samplePaths = [bgm, hole_effect, ball1, ball2, ball3];



class Sound {

    bgMusic!: HTMLAudioElement

    private _enableSfx: boolean = true
    private _volumeSfx: number = 100

    private _enableMusic: boolean = false
    private _volumeMusic: number = 100
    ballTypes = [new Audio(ball1), new Audio(ball2), new Audio(ball3)];
    hole = new Audio(hole_effect);
    samples: any[] = [];
    audioContext = new AudioContext;
    bgmPlaying: any;

    constructor() {
        this.bgMusic = new Audio(bgm)

    }

    async getFile(filePath: string) {
        const response = await fetch(filePath);
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
        return audioBuffer;
    }


    async setupSamples(paths: string[]) {
        console.log("Setting up samples");

        for (const path of paths) {
            const sample = await this.getFile(path);
            this.samples.push(sample);
        }
        
    }

    playSample(audioBuffer: any, time: number, gain: GainNode) {
        const sampleSource = this.audioContext.createBufferSource();
        sampleSource.buffer = audioBuffer;
        sampleSource.connect(gain);
        sampleSource.start(time);
        gain.connect(this.audioContext.destination);
        return sampleSource;
    }

   


    playBgMusic() {
        if (!this._enableMusic) {
            return this.bgmGain.gain.value=0;
        }

        try {
            
            if(!this.bgmPlaying){

                this.bgmPlaying = this.playSample(this.samples[0], 0, this.bgmGain);
                this.bgmPlaying.loop = true;
            }
        else this.bgmGain.gain.value = this.volumeMusic;
        } catch (ex) {
            console.warn(ex)
        }
    }

    playBall(type: number) {
        // this.audioContext.resume();
        if (!this._enableSfx) {
            this.sfxGain.gain.value = 0;
            return;
        }

        try {
            this.sfxGain.gain.value = this.volumeSfx;
            this.playSample(this.samples[type+2], 0, this.sfxGain);
        } catch (ex) {
            console.warn(ex);
        }
    }

    playHole() {
        if (!this._enableSfx) {
            this.sfxGain.gain.value = 0;
            return;
        }
        try {
            this.sfxGain.gain.value = this.volumeSfx;
            this.playSample(this.samples[1], 0, this.sfxGain);

        } catch (ex) {
            console.warn(ex)
        }
    }


    stopBgMusic() {
        this.bgmGain.gain.value = 0;
    }

    bgmGain = new GainNode(this.audioContext, {
        gain: 1,
      });
      sfxGain = new GainNode(this.audioContext, {
        gain: 1,
      });
      
      

    updateVolumeMusic(volume: number) {
        this.bgmGain.gain.value =  volume / 100;
   
        this.volumeMusic = volume / 100;

        // this.bgMusic.volume = (volume / 100) * 0.25
    }

    updateVolumeSfx(volume: number) {
        this.sfxGain.gain.value =  volume / 100;
        this.volumeSfx = volume / 100;

    }

    playSound(soundObj: HTMLAudioElement, soundVolume: number) {
        if (!soundObj || isNaN(soundVolume)) return;

        try {
            soundObj.volume = soundVolume / 100;
            const playPromise = soundObj.play();

            if (playPromise) {
                playPromise.then(() => {
                    // Automatic playback
                }).catch(err => {
                    console.error(err);
                });
            }
        } catch (ex) {
            console.warn(ex);
        }
    }
    /** getter & setter */
    set enableSfx(isEnable: boolean) {
        this._enableSfx = isEnable
    }

    get volumeSfx() {
        return this._volumeSfx
    }
    set volumeSfx(volume: number) {
        this._volumeSfx = volume
    }

    set enableMusic(isEnable: boolean) {
        this._enableMusic = isEnable
    }

    get volumeMusic() {
        return this._volumeMusic
    }
    set volumeMusic(volume: number) {
        this._volumeMusic = volume
    }
}

const sound = new Sound();
sound.setupSamples(samplePaths);

export default sound