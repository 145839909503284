import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hook";
import { formatCurrency } from "../../../tool/money";
import Percentage from "./percentage";
import Refer from "./refer.png";
import "./index.scss";
import useOrientation from "../../hook/Orientation";
import { getColor } from "../../color";
import { Player } from "../../user/auth/type";
import { getPlayer } from "../../user/auth/reducer";

interface HoleItem {
  baseColor: string;
  baseMultiple: number;
}

// Todo: game reducer

const Hole = () => {
  const { t, i18n } = useTranslation();
  const [hoverIdx, setHoverIdx] = useState(0);
  const language = i18n.language;
  const orientation = useOrientation();
  const { rows, risk,amount } = useAppSelector((state) => state.playerbet);
  const player = useAppSelector(getPlayer) as Player;
  const { player_balance, player_name, player_currency } = player;

  const [holeData, setHoleData] = useState<number[]>([]);
  const handleFormatCurrency = (amount: number): string =>{
    return formatCurrency(amount, player_currency, language);
  }
  const [ chance, setChance ] = useState({});
  const [ color, setColor ] = useState();

  useEffect(()=>{
   (async()=>{
    setColor(await getColor())
    setChance(await(await fetch('/chance.json')).json() )
  })()
  }, [])


  useEffect(() => {
    const activeHole = rows / 2 + 1;
  (async()=>{
    const prize = await(await fetch('/prize.json')).json() 
    const HoleData = prize[rows.toString() as any][risk.toLowerCase()];
  
    setHoleData(HoleData);
  })()
    // @ts-ignore
  }, [rows, risk]);

  const widthLabel: any = {
    8: 1400,
    10: 1700,
    12: 1900,
    14: 1950,
    16: 1900
  }

  return (
    <>
      {hoverIdx > 0 && Object.values(chance).length ? <Percentage profit={handleFormatCurrency(holeData[hoverIdx-1]*amount)} chance={((chance as any)[rows.toString()][risk.toString().toLowerCase()][hoverIdx-1])} /> : <></>}
      <div className="hole-wrapper">
        <div className="hole-container">
          <div className={`hole-list row-${rows}`}>
            {holeData.map((baseMultiple, idx) => {
              return (
                <div
                  key={idx}
                  className="list-item"
                  style={{
                    width: `${100 / holeData.length}%`,
                  }}
                >
                  <div className="item-hole">
                    <div
                      className="hole"
                      style={{
                        backgroundColor: `rgba(${color && color[rows][idx]},1)`,
                      }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={`light-list row-${rows}`}>
            {holeData.map((baseMultiple, idx) => {
              return (
                <div
                  key={"hole-" + idx}
                  className="list-item"
                  style={{
                    width: `${100 / holeData.length}%`,
                  }}
                >
                  <div
                    className="item-light"
                    style={{
                      backgroundColor: `rgba(0,0,0,0)`,
                      boxShadow: `0 -0.5rem 2rem 0rem rgba(${
                        color&&color[rows][idx]
                      },0.6)`,
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={`label-list row-${rows}`}>
          {holeData.map((baseMultiple, idx) => {
            return (
              <div
                key={"label-" + idx}
                className="list-item"
                style={{
                  backgroundColor: `rgba(${color && color[rows][idx]},1)`,
                  width: `${widthLabel[rows] / holeData.length}%`,
                }}
                onMouseOver={() => setHoverIdx(idx + 1)}
                onMouseLeave={() => setHoverIdx(0)}
              >
                {hoverIdx && hoverIdx - 1 === idx ? (
                  <img className="refer" alt="" src={Refer} />
                ) : (
                  <></>
                )}
                <div className="item-label" style={{}}>
                  <div className="label">{
                    baseMultiple.toFixed(2).split(".00").join("")
                    //.split("000").join("K")
                  // Number(baseMultiple).toFixed(2).substring(0,4)[Number(baseMultiple).toFixed(2).substring(0,4).length-1]=="."?((Number(baseMultiple).toFixed(2))).substring(0,3):(Number(baseMultiple).toFixed(2)).substring(0,4)
                  }</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Hole;
