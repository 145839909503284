import React, {useEffect, useRef, useState} from "react";

import {useTranslation} from "react-i18next";
import BGBet from "./bet.png";
import BGBetGreyS from "./bet-gray-s.png";
import BGAutoBetGrey from "./auto-bet-gray.png";
import BGBarPortrait from "./bar-form-portrait.png";

import BGBarLandscape from "./bar-form-landscape.png";
import BTNAUTO from "./auto-bet.webp";
import BTNSTOP from "./stop-bet.webp";
import BGRect from "./rect.png";
import "./index.scss";
import DropDown from "./dropdown";
import {useAppDispatch, useAppSelector} from "../../store/hook";
import {getPlayer} from "../user/auth/reducer";
import {LimitBetKeys, Player} from "../user/auth/type";
import {formatCurrency} from "../../tool/money";
import useOrientation from "../hook/Orientation";
import {changeAmount, changeBall, changeRisk, changeRow, updateTotalAmountAuto} from "./reducer";
import {endAuto, resetResult, sendAutoBetAction, sendBetAction, startAuto,} from "../result/reducer";
import {clearLastHistory} from "../history/reducer";
import {debounce} from "../../tool/debounce";

let autotimeout: any
const BetBar = () => {
    const {t, i18n} = useTranslation();
    const language = i18n.language;
    const orientation = useOrientation();
    const dispatch = useAppDispatch();
    const playerbet = useAppSelector((state) => state.playerbet);
    const result = useAppSelector((state) => state.result);
    const limit_bet = useAppSelector(
        (state) => state.auth.game?.limit_bet
    ) as Record<LimitBetKeys, number>;
    const [disable, setDisable] = useState(false);
    const [disableAuto, setDisableAuto] = useState(true);
    const [auto, setAuto] = useState(false);
    const [stoping, setStopingAuto] = useState(false);
    const current_round = useAppSelector(
        (state) => state.history.current_round
    ) as number;

    const rowList = playerbet.row_list;
    const riskList = playerbet.risk_list;
    const ballList = playerbet.ball_list;

    const player = useAppSelector(getPlayer) as Player;

    const status = useAppSelector((state) => state.result!.status);
    const error = useAppSelector((state) => state.result!.error);
    const [insuficient, setInsuficient] = useState(false);
    useEffect(() => {
        if (status.indexOf("end-spin") > -1 && !result.auto && result.inQueue === 0) {
            disable && setDisable(false);
            stoping && setStopingAuto(false);
        }
    }, [status, disable, stoping, result.auto, result.inQueue]);

    useEffect(() => {
        if (status === "force-end-spin") {
            handleStopAutoBet()
        }
    }, [status]);


    useEffect(() => {
        // console.log(status,  error)
        if (status === "failed") {
            setDisable(false);
            setAuto(false);
            setStopingAuto(false);
            if (playerbet.balls === 1) {
                setDisableAuto(false);
            } else {
                setDisableAuto(true);
            }
        }
    }, [status, error, playerbet.balls]);


    useEffect(() => {
        if (playerbet.balls === 1) {
            setDisableAuto(false);
        } else {
            setDisableAuto(true);
        }
    }, [playerbet.balls]);

    const {player_balance, player_name, player_currency} = player;

    const handleFormatCurrency = (amount: number): string => {
        return formatCurrency(amount, player_currency, language);
    };

    const defaultAmount = Number(limit_bet.minimal);
    const handleFormatAmount = (value: string) => {
        const newAmount = Number(
            value.split(".").join("").split("Rp").join("").split(",").join(".")
        ).toString();

        return newAmount;
    };

    const amountRef = useRef();

    const handleDecAmount = () => {
        const newAmount =
            // @ts-ignore
            Number(handleFormatAmount(amountRef.current!.value)) * 0.5 >=
            limit_bet.minimal
                ? // @ts-ignore
                Number(handleFormatAmount(amountRef.current!.value)) * 0.5
                : Number(handleFormatAmount(limit_bet.minimal.toString()));
        // @ts-ignore
        amountRef.current!.value = handleFormatCurrency(newAmount);

        dispatch(
            // @ts-ignore
            changeAmount(Number(handleFormatAmount(amountRef.current!.value)))
        );
    };

    const handleIncAmount = () => {
        const newAmount =
            // @ts-ignore
            Number(handleFormatAmount(amountRef.current!.value)) * 2 <=
            limit_bet.maximal
                ? // @ts-ignore
                Number(handleFormatAmount(amountRef.current!.value)) * 2
                : Number(handleFormatAmount(limit_bet.maximal.toString()));

        // @ts-ignore
        amountRef.current!.value = handleFormatCurrency(newAmount);
        // @ts-ignore
        dispatch(
            // @ts-ignore
            changeAmount(Number(handleFormatAmount(amountRef.current!.value)))
        );
    };

    const handleRowChange = (row: string | number) => {
        if (disable) return;

        dispatch(changeRow(Number(row)));
    };

    const handleBallChange = (ball: string | number) => {
        if (disable) return;

        dispatch(changeBall(Number(ball)));
    };

    const handleRiskChange = (risk: string | number) => {
        if (disable) return;

        dispatch(changeRisk(risk as string));
    };

    useEffect(() => {
        // @ts-ignore
        const val = amountRef.current?.value;
        // @ts-ignore
        if (!Number.isNaN(Number(handleFormatAmount(val)))) {
            dispatch(changeAmount(Number(handleFormatAmount(val))));
            dispatch(changeBall(Number(playerbet.balls)));
        } // @ts-ignore
    }, [amountRef, amountRef.current, amountRef.current?.value, playerbet.ball]);

    const handleBet = () => {
        if (disable || (!disable && insuficient)) return;

        setDisable(true);
        dispatch(resetResult());
        dispatch(clearLastHistory());
        const sendBet = {
            game: "DPLK0001",
            total_amount: playerbet.amount * playerbet.balls,
            button_bet: {
                rows: playerbet.rows,
                risk: playerbet.risk.toLowerCase(),
                balls: playerbet.balls,
                amount: playerbet.amount,
                // round_id: current_round+1
            },
        };
        setTimeout(() => {
            dispatch(sendBetAction(sendBet));
        }, 500);
    };

    const handleAutoBet = () => {
        if (disableAuto || disable || stoping || (!disable && insuficient)) return;
        dispatch(resetResult());
        dispatch(clearLastHistory());
        const sendBet = {
            game: "DPLK0001",
            total_amount: playerbet.amount * playerbet.balls,
            button_bet: {
                rows: playerbet.rows,
                risk: playerbet.risk.toLowerCase(),
                balls: 1,
                amount: playerbet.amount,
                round_id: current_round
            },
            round_id: current_round
        };
        dispatch(startAuto());
        dispatch(sendAutoBetAction(sendBet));
        setAuto(true);
        setDisable(true);
        dispatch(updateTotalAmountAuto(0))
    };

    const handleStopAutoBet = () => {
        setAuto(false);
        setStopingAuto(true);
        dispatch(endAuto());
    };

    const user = useAppSelector(getPlayer) as Player;

    useEffect(() => {
        if (playerbet.amount * playerbet.balls > user.player_balance) {
            setInsuficient(true);
        } else {
            setInsuficient(false);
        }
    }, [playerbet.amount * playerbet.balls, user.player_balance]);

    return (
        <div className="bet-wrapper">
            <div className="bet">
                {disable ? <div className="disable"></div> : <></>}
                <img
                    className="bet-bg"
                    alt=""
                    src={orientation == "landscape" ? BGBarLandscape : BGBarPortrait}
                />
                <div className="bet-form">
                    <div className="form-group bet-form-bet">
                        <label htmlFor="amount">{t("betbar.bet_amount")}</label>
                        <input
                            //@ts-ignore
                            ref={amountRef}
                            type="text"
                            id="amount"
                            name="amount"
                            maxLength={7}
                            defaultValue={handleFormatCurrency(defaultAmount)}
                            onKeyDownCapture={(e) => {
                                const charTyped = e.key;
                                const letterRegex = /[^0-9]/;
                                if (!charTyped.match(letterRegex)) {
                                    try {
                                        // @ts-ignore
                                        if (e.target.value.split(".")[1].length >= 2 && window.getSelection().getRangeAt(0).startOffset == e.target.value.length) {
                                            return e.preventDefault();
                                        }
                                    } catch (error) {

                                    }
                                } else if (e.key == "Backspace") {
                                } else if (e.key.indexOf("Arrow") > -1) {
                                } else if (e.key == ".") {
                                    // @ts-ignore
                                    if (e.target.value.indexOf(".") > 0) {
                                        return e.preventDefault();
                                    }
                                } else {
                                    return e.preventDefault();
                                }
                            }}
                            onChange={(e) => {
                                if (Number.isNaN(Number(e.target.value))) return;
                                dispatch(
                                    // @ts-ignore
                                    changeAmount(Number(handleFormatAmount(e.target.value)))
                                );
                            }}
                            onFocus={({target}) => {
                                target.value = playerbet.amount.toString();
                            }}
                            onBlur={({target}) => {
                                if (limit_bet.minimal >= Number(target.value)) {
                                    target.value = handleFormatCurrency(
                                        Number(limit_bet.minimal)
                                    );
                                } else if (limit_bet.maximal <= Number(target.value)) {
                                    target.value = handleFormatCurrency(
                                        Number(limit_bet.maximal)
                                    );
                                } else {
                                    target.value = handleFormatCurrency(Number(target.value));
                                }

                                dispatch(
                                    changeAmount(Number(handleFormatAmount(target.value)))
                                );
                            }}
                        />
                        <div className="bet-form-tool-list">
                            <div
                                className="tool-item"
                                onClick={() => {
                                    handleDecAmount();
                                }}
                            >
                                <span>1/2</span>
                                <img className="bet-form-rect" alt="" src={BGRect}/>
                            </div>
                            <div className="tool-item" onClick={() => handleIncAmount()}>
                                <span>2x</span>
                                <img className="bet-form-rect" alt="" src={BGRect}/>
                            </div>
                        </div>
                    </div>
                    <DropDown
                        key={"bet-form-ball"}
                        className={"bet-form-ball"}
                        label={t("betbar.ball")}
                        options={ballList}
                        setSelected={handleBallChange}
                        defaultValue={playerbet.balls}
                    />
                    <DropDown
                        key={"bet-form-risk"}
                        className={"bet-form-risk"}
                        label={t("betbar.risk")}
                        options={riskList}
                        setSelected={handleRiskChange}
                        defaultValue={playerbet.risk}
                    />
                    <DropDown
                        key={"bet-form-rows"}
                        className={"bet-form-rows"}
                        label={t("betbar.rows")}
                        options={rowList}
                        setSelected={handleRowChange}
                        defaultValue={playerbet.rows}
                    />
                </div>
                {/* {playerbet.balls == 1 ? ( */}
                <>
                    {!auto ? (
                        <div className="bet-button auto" onClick={() => handleAutoBet()}>
                            <img
                                className="bet-button-bg"
                                alt=""
                                src={
                                    disableAuto || disable || stoping || insuficient
                                        ? BGAutoBetGrey
                                        : BTNAUTO
                                }
                            />
                        </div>
                    ) : (
                        <div
                            className="bet-button auto"
                            onClick={() => handleStopAutoBet()}
                        >
                            <img className="bet-button-bg" alt="" src={BTNSTOP}/>
                        </div>
                    )}
                    <div className="bet-button non-auto" onClick={debounce(() => handleBet(), 1000)}>
                        <img
                            className="bet-button-bg"
                            alt=""
                            src={disable || (!disable && insuficient) ? BGBetGreyS : BGBet}
                        />
                        <span className="button-text">{t(`betbar.button`)}</span>
                    </div>
                </>
                {/* )
        // : (
        //   <div className="bet-button center" onClick={() => handleBet()}>
        //     <img
        //       className="bet-button-bg"
        //       alt=""
        //       src={disable || (!disable && insuficient) ? BGBetGreyS : BGBet}
        //     />
        //     <span className="button-text">{t(`betbar.button`)}</span>
        //   </div>
            // )*/}
            </div>
        </div>
    );
};

export default BetBar;
