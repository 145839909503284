import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { formatCurrency } from "../../tool/money";
import useOrientation from "../hook/Orientation";
// import BGLandscape from "./bg-landscape.png";
import BGPortrait from "./bg-portrait.png";
// import FrameLandscape from "./frame-landscape.png";
import FramePortrait from "./frame-portrait.png";

import BGLandscape from "./top_16_rows.png";
import FrameLandscape from "./bottom_16_rows.png";

import Hole from "./hole";
// import Hole from './hole'
// import Plinko from "./Plinko";
import PlinkoIos from "./PlinkoSAT";
import "./index.scss";
import { Endpoints } from "../../const/Api";
import {
  addedHistory,
  addedLastHistory,
  refreshHistory,
} from "../history/reducer";
import {
  finishAnimation,
  resetResult,
  sendBetAction,
  startAnimation,
  updateTotalWin,
} from "../result/reducer";
import { updateBalance } from "../user/auth/reducer";
import { Result } from "../result/type";
import { updateTotalAmountAuto } from "../betbar/reducer";
let clearAuto: any;
let collideSetting: any;
const Game = () => {
  const { t, i18n } = useTranslation();
  const orientation = useOrientation();
  const language = i18n.language;
  const canvasRef = useRef(null);
  const { rows, risk, amount, balls, total_amount, round_id } = useAppSelector(
    (state) => state.playerbet
  );
  const { result, status, total_win, payload, singleCount, auto } =
    useAppSelector((state) => state.result);
  const [plinkoInstance, setPlinkoInstance] = useState<any>();
  // const [machine, setMachine] = useState<any>(false);
  const [old_plinkoInstance, old_setPlinkoInstance] = useState<any>();
  const [resultState, setResultState] = useState<any>([]);
  const dispatch = useAppDispatch();
  const playerbet = useAppSelector((state) => state.playerbet);

  const toPlinko = result;

  useEffect(() => {
    // @ts-ignore
    //  if(!canvasRef.current!.querySelector(".game-canvas canvas")){
    if (!document.querySelector("canvas")) {

      const plinko = PlinkoIos.create(canvasRef.current, Number(rows), false);
      // setMachine(plinko.machine)
      dispatch(resetResult());
      setPlinkoInstance(plinko);
      // return () => {
      //   plinko.stop();

      //   // @ts-ignore
      //  //  canvasRef.current.removeChild(plinko.machine.render.canvas)
      // };
    }
    //  }
  }, []);

  useEffect(() => {
    if(plinkoInstance){
      dispatch(resetResult());
      plinkoInstance.changeRow(rows)
    }
  }, [rows, plinkoInstance]);


  // useEffect(() => {
  //   if (status === "idle" && plinkoInstance) {
  //     old_setPlinkoInstance(plinkoInstance);
  //     setPlinkoInstance(null);
  //   }
  // }, [status, plinkoInstance, old_setPlinkoInstance]);

  useEffect(() => {
    if (toPlinko.length && status === "succeeded") {
      dispatch(startAnimation());
      (async () => {
        collideSetting = collideSetting || await (await fetch("/collide.json")).json();
        setTimeout(() => {
          dispatch(refreshHistory());
        }, collideSetting.delay);
      })();
      // if (singleCount === 0) {
      //   ( () => {
      //     old_plinkoInstance.stop();
      //     const plinko = Plinko.create(canvasRef.current, Number(rows));
      //     setPlinkoInstance(plinko);
      //   })();
      // }
      // if (singleCount > 0) {
      //   dispatch(startAnimation());
      // }
      // if (singleCount === -1){

      //   ( () => {
      //     old_plinkoInstance.stop();
      //     const plinko = Plinko.create(canvasRef.current, Number(rows));
      //     setPlinkoInstance(plinko);
      //     dispatch(startAnimation());
      //   })();
      // }
    }
  }, [
    toPlinko,
    toPlinko?.length,
    // plinkoInstance,
    // old_plinkoInstance,
    // rows,
    dispatch,
    status,
    // singleCount
  ]);

  useEffect(() => {
    try {
      if (toPlinko.length && status === "start-spin" && plinkoInstance) {
        if (toPlinko.length === 1 && singleCount > -1) {
          // const plinko = Plinko.create(canvasRef.current, Number(rows), false);

          const off = plinkoInstance.singleAnimate(
            toPlinko[0],
            (data: Result) => {
              let new_round_id = payload.round_id;
              const newHistory = {
                idgameresult: 335,
                round_id: new_round_id,
                player_id: "3776914",
                agent_id: "125",
                now: Date.now(),
                created_date: "2022-09-20 10:52:37",
                data: {
                  detail_bet: {
                    rows,
                    risk,
                    balls,
                    round_id: new_round_id,
                    amount: amount,
                  },
                  total_amount: total_amount,
                  total_win: data.prize.value * amount,
                  last_balance: 6926712,
                },
                ...data,
              };
              // dispatch(updateTotalWin(data.prize.value * amount));
              dispatch(updateBalance(data.prize.value * amount));
              dispatch(updateTotalAmountAuto(amount));
              dispatch(addedLastHistory(newHistory));
              off();
              dispatch(finishAnimation(singleCount));
            },
            singleCount
          );
        } else {
       

          plinkoInstance.animate(toPlinko, (data: Result, idx: number) => {
            let new_round_id = payload.round_id;
            const newHistory = {
              idgameresult: 335,
              round_id: new_round_id,
              player_id: "3776914",
              agent_id: "125",
              now: Date.now(),
              created_date: "2022-09-20 10:52:37",
              data: {
                detail_bet: {
                  rows,
                  risk,
                  balls,
                  round_id: new_round_id,
                  amount: amount,
                },
                total_amount: total_amount,
                total_win: data.prize.value * amount,
                last_balance: 6926712,
              },
              ...data,
            };
            dispatch(updateTotalWin(data.prize.value * amount));
            dispatch(updateBalance(data.prize.value * amount));
            dispatch(addedLastHistory(newHistory));
            if (idx === toPlinko.length - 1) {
              dispatch(finishAnimation());
              // dispatch(resetResult());
            }
          });
        }
      }
    } catch (e) {
      console.log(e)
    }

  }, [
    toPlinko,
    toPlinko?.length,
    dispatch,
    status,
    plinkoInstance,
    balls,
    rows,
    risk,
    amount,
    total_amount,
    payload,
    singleCount,
  ]);

  return (
    <div className="game">
      <img
        className="game-bg big"
        alt=""
        src={orientation == "landscape" ? BGLandscape : BGPortrait}
      />
      <div className="game-frame border-frame"></div>
      <img
        className="game-frame big"
        alt=""
        src={orientation == "landscape" ? FrameLandscape : FramePortrait}
      />

      <style >
        {
          `
    .landscape .game-canvas {
      position: absolute;
      left: 24.6rem;
      width: 50%;
      display: flex;
      justify-content: center;
      padding-left: 1.5rem;
      top: -2rem;
    }
    .landscape  canvas {
        width: 100%;
        z-index: 4;
        position: absolute;
    }
    .portrait
    .game-canvas {
      position: absolute;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-left: 1.5rem;
      top: 30rem;
      
  }
  .portrait .game-canvas canvas {
    width: 100%;
    z-index: 4;
    position: absolute;

}
  `
        }
      </style>
      <div ref={canvasRef} className="game-canvas"></div>
      <Hole />
    </div>
  );
};

export default Game;
